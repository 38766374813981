/**
 *
 * state状态
 * @typedef {Object} AppState
 * @description 应用本身使用的redux state
 * @property {boolean} memberVisible: 是否展示社区成员Modal
 * @property {boolean} channelVisible: 是否展示创建频道Modal
 * @property {boolean} inviteVisible: 是否展示邀请加入社区Modal
 * @property {boolean} channelDelVisible: 是否展示确认删除频道Modal
 * @property {Object} inviteChannelInfo: 邀请加入channel的信息
 * @property {Object} curRtcChannelInfo: 当前通话的rtc频道信息
 * 
 */

const channel = {
  state: {
    memberVisible: false,
    channelVisible: false,
    channelVisibleModalChannelId: "", //编辑频道弹框选择的频道id
    inviteVisible: false,
    inviteChannelInfo: {},
    threadMap: new Map(),
    channelMemberVisible: true,
    channelUserMap: new Map(),
    createChannelCategoryId: "", //创建频道的分组id
    newChannelGroupId: "", //新建频道默认选中的分组id
    curRtcChannelInfo: {},
    settingChannelInfo: {},
    shareServerVisible: false,  //分享服务器显示标识

    channelDelVisible: false,
    channelDelVisibleModalChannelId: "", //删除频道弹框选择的频道id

    msgPicPreViewVisible: false, //图片预览弹框
    msgPicPreViewInfo: {}, //图片预览对象

    reDrawPicViewVisible: false, //重绘图片弹框

    reDrawPicContrastVisible: false, //重绘图片对比查看弹框

    quickInputValueObj: {}, //快速输入框显示数据对象

    currentChannelId: '', //当前频道id

    autoSelectFlag: false, //是否自动选中频道
  },
  reducers: {
    /**
     * @description 更新state
     * @param state: 当前state
     * @param payload: 更新state对象
     */
    updateState(state, payload) {
      return {
        ...state,
        ...payload
      };
    },
    updateThreadMap(state, payload) {
      const { channelId, threadInfo } = payload;
      const { threadMap } = state;
      return {
        ...state,
        threadMap: new Map([
          ...threadMap,
          ...new Map().set(channelId, threadInfo)
        ])
      };
    },
    removeThreadMap(state, payload) {
      const { channelId } = payload;
      const { threadMap } = state;
      threadMap.delete(channelId);
      return {
        ...state,
        threadMap
      };
    },
    updateThreadInfo(state, updateInfo) {
      const { id, operation, parentId } = updateInfo;
      if (state.threadMap.has(parentId)) {
        const list = [...state.threadMap.get(parentId)?.list] || [];
        const findIndex = list.findIndex((item) => item.id === id);
        if (findIndex > -1) {
          switch (operation) {
            case "update":
              const info = { ...list[findIndex], name: updateInfo.name };
              list.splice(findIndex, 1, info);
              break;
            case "destroy":
              list.splice(findIndex, 1);
              break;
            default:
              break;
          }
          return {
            ...state,
            threadMap: new Map([
              ...state.threadMap,
              ...new Map().set(parentId, {
                ...state.threadMap.get(parentId),
                list
              })
            ])
          };
        }
      }
      return state;
    },
    updateChannelUserMap(state, payload) {
      const { channelId, userListInfo } = payload;
      const { channelUserMap } = state;
      return {
        ...state,
        channelUserMap: new Map([
          ...channelUserMap,
          ...new Map().set(channelId, userListInfo)
        ])
      };
    },
  },
  effects: {
    setVisible(visible) {
      this.updateState({ memberVisible: visible });
    },
    setChannelVisible(visible) {
      if(visible){
        this.updateState({ channelVisible: visible });
      }
      else{
        this.updateState({ newChannelGroupId: '', channelVisible: visible });
      }
    },
    updateChannelVisibleModalChannelId(id){
      this.updateState({ channelVisibleModalChannelId: id });
    },
    setInviteVisible(visible) {
      this.updateState({ inviteVisible: visible });
    },
    setShareServerVisible(visible){
      this.updateState({ shareServerVisible: visible });
    },
    setChannelMemberVisible(visible) {
      this.updateState({ channelMemberVisible: visible });
    },
    setThreadMap({ channelId, threadInfo }) {
      this.updateThreadMap({ channelId, threadInfo });
    },
    setChannelUserMap({ channelId, userListInfo }) {
      this.updateChannelUserMap({ channelId, userListInfo });
    },
    updateChannelThreadInfo({ updateInfo }) {
      this.updateThreadInfo(updateInfo);
    },
    deleteChannelThreadMap({ channelId }) {
      this.removeThreadMap({ channelId });
    },
    updateCreateChannelCategoryId({ categoryId }) {
      this.updateState({ createChannelCategoryId: categoryId });
    },
    updateNewChannelGroupId({ categoryId }) {
      this.updateState({ newChannelGroupId: categoryId });
    },
    setInviteChannelInfo({ inviteChannelInfo }) {
      this.updateState({ inviteChannelInfo });
    },
    setCurRtcChannelInfo(curRtcChannelInfo) {
      this.updateState({ curRtcChannelInfo })
    },
    setSettingChannelInfo(settingChannelInfo) {
      this.updateState({ settingChannelInfo })
    },
    setChannelDelVisible(visible) {
      this.updateState({ channelDelVisible: visible });
    },
    updateChannelDelVisibleModalChannelId(id) {
      this.updateState({ channelDelVisibleModalChannelId: id });
    },
    setMsgPicPreViewVisible(visible) {
      this.updateState({ msgPicPreViewVisible: visible });
    },
    setReDrawPicViewVisible(visible) {
      this.updateState({ reDrawPicViewVisible: visible });
    },
    setReDrawPicContrastVisible(visible) {
      this.updateState({ reDrawPicContrastVisible: visible });
    },
    updateMsgPicPreViewInfo(msgPicPreViewInfo) {
      this.updateState({ msgPicPreViewInfo: msgPicPreViewInfo });
    },
    setQuickInputValueObj(quickInputValueObj) {
      this.updateState({ quickInputValueObj: {...quickInputValueObj} })
    },
    setCurrentChannelId(id) {
      this.updateState({ currentChannelId: id });
    },
    setAutoSelectFlag(flag) {
      this.updateState({ autoSelectFlag: flag });
    }
  }
};

export default channel;
