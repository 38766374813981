import { post, get, put, del } from "@/utils/axios";

const PATH = "";

//获取描述词库表单
export function getDescriptorClass(data = {}) {
  return get("/api/v1/descriptorClass", data);
}

//获取二级描述词库
export function getDescriptorClassSubInfo(data = {}) {
  return get("/api/v1/descriptorClassSubInfo", data);
}

//随机获取描述词
export function getthemeDescriptor(data = {}) {
  return get("/api/v1/themeDescriptor", data);
}

//发送自定义消息
export function postChannelMessage(data = {}) {
  return post("/api/v1/channel/message", data);
}

//获取用户信息
export function getUserInfo(data = {}) {
  return get("/api/v1/hx/userInfo", data);
}

//获取oss上传token
export function postUploadToken(data = {}) {
  return get("/api/v1/upload/token", data);
}

//获取oss上传token
export function getServiceOssUploadUrl(data = {}) {
  return get("/service/v1/service/oss/upload_url", data);
}

//获取所有主题
export function getAllTheme(data = {}) {
  return get("/api/v1/allTheme", data);
}

//提交AI创作
export function postAIWork(data = {}) {
  return post("/api/v1/work", data);
}

//获取所有主题
export function getTrainingMode(data = {}) {
  return get("/api/v1/training/mode", data);
}

//添加训练
export function postModeTraining(data = {}) {
  return post("/api/v1/mode/training", data);
}

//检查训练文件是否合格
export function postModeCheckFile(data = {}) {
  return post("/api/v1/mode/checkFile", data);
}

//编辑训练模型信息
export function putModeTraining(data = {}) {
  return put("/api/v1/mode/training", data);
}

//删除模型
export function delModeTraining(data = {}) {
  return del(`/api/v1/mode/training/${data.mode_id}`, data);
}

//创建新频道
export function postServerChannel(data = {}) {
  return post("/api/v1/server/channel", data);
}

//编辑频道
export function putServerChannel(data = {}) {
  return put("/api/v1/server/channel", data);
}

//删除频道
export function delServerChannel(data = {}) {
  return del(`/api/v1/server/channel/${data.server_id}/${data.channel_id}`, data);
}

//创建服务器
export function postCircleServer(data = {}) {
  return post("/api/v1/circle/server", data);
}

//更新服务器
export function putCircleServer(data = {}) {
  return put("/api/v1/circle/server", data);
}

//获取服务器信息
export function getCircleServer(data = {}) {
  return get(`/api/v1/circle/server`, data);
}

//绘画作品点击
export function postWorkClick(data = {}) {
  return post("/api/v1/work/click", data);
}

//绘画作品点赞或踩
export function postWorkLikeOrTread(data = {}) {
  return post("/api/v1/work/likeOrTread", data);
}

//训练消息点赞
export function postMessageLike(data = {}) {
  return post("/api/v1/message/like", data);
}

//获取邀请码
export function postInviteCode(data = {}) {
  return post("/api/v1/invite/code", data);
}

//获取密码
export function getServerPassword(data = {}) {
  return get("/api/v1/server/password", data);
}

//获取code服务器信息
export function getInviteTrigger(data = {}) {
  return get(`/api/v1/invite/trigger/${data.code}`, data);
}

//根据邀请链接 加入频道或者社区
export function postInviteTrigger(data = {}) {
  return post(`/api/v1/invite/trigger/${data.code}`, data);
}

//共创首页
export function postTogetherIndex(data = {}) {
  return post("/api/v1/together/index", data);
}

//灵感首页推荐
export function getInspirationRecommend(data = {}) {
  if(data.keyword){
    return get("/api/v1/inspiration/search", data);
  }
  else{
    return get("/api/v1/inspiration/recommend", data);
  }
  
  
}

//灵感首页搜素
export function getInspirationSearch(data = {}) {
  return get("/api/v1/inspiration/search", data);
}

//AI重绘
export function postWorkEdit(data = {}) {
  return post("/api/v1/work/edit", data);
}

//用户进入服务器
export function postCircleJoin(data = {}) {
  return post("/api/v1/circle/join", data);
}


// //删除频道
// export function delServerChannel(data = {}) {
//   return del(`/api/v1/server/channel/${data.server_id}/${data.channel_id}`, data);
// }

//移除频道用户
export function delChannelUser(data = {}) {
  return del("/api/v1/channel/user", data);
}

//内容审核接口
export function postContentReview(data = {}) {
  return post("/api/v1/content/review", data);
}

//获取训练信息
export function getTogetherInfo(data = {}) {
  return get(`/api/v1/together/info/${data.trainId}`, data);
}

//获取模型信息
export function getModeInfo(data = {}) {
  return get(`/api/v1/mode/info/${data.modeId}`, data);
}

//获取频道信息
export function getChannelInfoApi(data = {}) {
  return get(`/api/v1/server/channel/${data.channelId}`, data);
}

//获取渠道内用户
export function getChannelUsers(data = {}) {
  return get(`/api/v1/channel/users`, data);
}

//更新频道内用户相关权限与禁言
export function putChannelUsers(data = {}) {
  return put(`/api/v1/channel/users`, data);
}

//更新频道内用户相关权限与禁言
export function delCircleServerUsers(data = {}) {
  return del(`/api/v1/circle/server/users`, data);
}

//获取今日可用绘画次数
export function getDrawingNum(data = {}) {
  return get(`/api/v1/drawing/num`, data);
}

//图片审核接口
export function postAtlasImages(data = {}) {
  return post("/api/v1/atlas/images", data);
}

//创建服务器频道分组
export function postServerGroup(data = {}) {
  return post("/api/v1/server/group", data);
}

//更新频道分组
export function putServerGroup(data = {}) {
  return put("/api/v1/server/group", data);
}

//删除频道分组
export function delServerGroup(data = {}) {
  return del("/api/v1/server/group", data);
}


//用户进入频道
export function postChannelJoin(data = {}) {
  return post("/api/v1/channel/join", data);
}

//用户模型列表
export function getTrainingModeForUser(data = {}) {
  return get(`/api/v1/training/modeForUser`, data);
}

//服务器频道列表
export function getServerChannelDetail(data = {}) {
  return get(`/api/v1/server/channel`, data);
}


//获取用户服务器所属角色
export function getServerRole(data = {}) {
  return get(`/api/v1/server/role`, data);
}

// //AI新手指导
export function getServiceAiBeginnerGuide(data = {}) {
  return get("/activityunion/ai_beginner_guide/index", data);
}

//根据主题ID获取词库分类及描述词
export function getDescriptorClassByThemeId(data = {}) {
  return get("/api/v1/descriptorClassByThemeId", data);
}

//作品信息
export function getWorkInfo(data = {}) {
  return get("/api/v1/work/info", data);
}

//用户作品-重绘跳转逻辑
export function getWorkUserListRedrawJump(data = {}) {
  return get("/api/v1/work/userList/redrawJump", data);
}

//用户作品-新图片数量
export function getWorkUserListNewImgNum(data = {}) {
  return get("/api/v1/work/userList/newImgNum", data);
}