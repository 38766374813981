// import WebIM from "./Easemob-chat";
import WebIM from 'easemob-circle';

WebIM.conn = new WebIM.connection({
  //  Pass your appKey here.
  // appKey: 'easemob-demo#jzz1',
  appKey: process.env.REACT_APP_ENV == 'pro'?'1126240125169917#aico':'1126240125169917#demo',
  // appKey: '1126240125169917#aico',
});

WebIM.logger.disableAll();

export default WebIM;
