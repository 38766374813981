/**
 * 网络请求配置
 */
import axios from "axios";
import store from "../store";
const { getState } = store;




const BASE_URL = {
    dev: "",
    test: "https://aitest.3d66.com",
    pro: "https://ai.3d66.com",
};

const API_BASE_URL = {
    dev: "",
    test: "https://apitest.3d66.com",
    pro: "https://api.3d66.com",
};

const SERVICE_API_URL = {
    dev: "",
    test: "https://servicetest.3d66.com/",
    pro: "https://service.3d66.com/",
};

const env = process.env.REACT_APP_ENV || 'dev';

// axios.defaults.baseURL = BASE_URL[env];
// axios.defaults.withCredentials=true;

// axios.defaults.timeout = 100000;

const Axios = axios.create({
    baseURL: BASE_URL[env],
    timeout: 1800000,
    withCredentials: true,
  });

//   Axios.defaults.withCredentials = true; // 允许携带cookie 
//   axios.defaults.withCredentials = true; // 允许携带cookie 
//   Axios.defaults.headers.common['headers'] = localStorage.getItem("imToken");
//   Axios.defaults.headers.common['Authorization'] = `Bearer ${getState().app?.userInfo?.accessToken}`

const config = {
    headers:{
        'Authorization': `Bearer ${getState().app?.userInfo?.accessToken}`,
        'headers': localStorage.getItem("imToken")
    }
};


//设置Axios请求头
// Axios.defaults.headers.common['access-source-page'] = window.referrerType || 0;
// Axios.defaults.headers.common['source-type '] = window.currentType || 0;

/**
 * http request 拦截器
 */

let currentType = 0;
if(window.location.href.indexOf('coCreateHomePage')>=0){
    currentType = 34;
}
else if(window.location.href.indexOf('inspirationHomePage')>=0){
    currentType = 33;
}
else{
    currentType = 35;
}

Axios.interceptors.request.use(
    (config) => {
        // config.data = JSON.stringify(config.data);
        config.headers.imToken = localStorage.getItem("imToken");
        // config.headers['Access-Source-Page'] = window.referrerType || currentType;
        // config.headers['Page-Type ']  = window.currentType || currentType;
        // //debugger;
        config.headers['Authorization'] = `Bearer ${getState().app?.userInfo?.accessToken}`;
        if (config.url.indexOf('/service') == 0) {
            // config.baseURL = APIS[config?.apiType];
            config.baseURL = API_BASE_URL[env];
            delete config.headers['imToken'];
        }
        else if (config.url.indexOf('/activityunion') >= 0){
            config.baseURL = SERVICE_API_URL[env];
            delete config.headers['imToken'];
        }
        else if (config.url.indexOf('.aliyuncs.com/ai/') >= 0){
            delete config.headers['imToken'];
        }
        if(config.method === 'get' || config.method === 'delete'){
            let newParam = config.params;
            newParam.accessSourcePage = window.referrerType || currentType;
            newParam.pageType  = window.currentType || currentType;
            newParam.ai_source_2 = getState().app.ai_source;
            config.params = newParam;
        }
        else{
            let newParam = config.data;
            newParam.accessSourcePage = window.referrerType || currentType;
            newParam.pageType  = window.currentType || currentType;
            newParam.ai_source_2 = getState().app.ai_source;
            config.data = newParam;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */
Axios.interceptors.response.use(
    (response) => {
        if (response.data.errCode === 2) {
           // console.log("过期");
        }
        return response;
    },
    (error) => {
       // console.log("请求出错：", error);
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}, config={}) {
    return new Promise((resolve, reject) => {
        Axios.get(url, {
            params: params,
        },{
            ...config
        }).then((response) => {
           // console.log("get请求参数");
            // landing(url, params, response.data);
            resolve(response.data);
        })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        Axios.post(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data, config={}) {
    return new Promise((resolve, reject) => {
        Axios.put(url, data, {
            ...config,
        }).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, data) {
    return new Promise((resolve, reject) => {
        Axios.delete(url, {
            params: data,
        }).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export default function http(method, url, param) {
    return new Promise((resolve, reject) => {
        switch (method) {
            case "get":
                get(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
                break;
            case "post":
                post(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
                break;
            case "put":
                put(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
                break;
            case "del":
                del(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}


/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
    if (data.code === -1) {
    }
}

