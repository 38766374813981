const cuttingRulesBaseFunc = (url, suffix, suffixFunc) => {
  if(url.indexOf('aiimg.3d66.com')>=0 || url.indexOf('imgo.3d66.com')>=0 || url.indexOf('3d66.oss-cn-hangzhou.aliyuncs.com')>=0 || url.indexOf('3d66-imgo.oss-cn-hangzhou.aliyuncs.com')>=0){
    //oss域名的地址
    if(url.indexOf('!')>=0){
      //带了别名的裁图规则
      /*
        1、去掉别名
        2、拼接新的裁图规则
      */
      url = url.split('!')[0];
      url = url + '?x-oss-process=image' + suffix;
    }
    else if(url.indexOf('?x-oss-process')>=0){
      //带了裁图规则的地址
      url = url + suffix; 
    }
    else{
      //不带裁图规则的地址
      url = url + '?x-oss-process=image' + suffix;
    }
    return url;
  }
  else{
    return url;
  }
};

//原!ai_index_10别名的裁图规则
const cuttingRules1 = (url) => {
  return cuttingRulesBaseFunc(url, '/resize,m_lfit,h_200/quality,Q_100/sharpen,80/format,avif')
}

//原!ai_index_7别名的裁图规则
const cuttingRules2 = (url) => {
  return cuttingRulesBaseFunc(url, '/resize,m_lfit,w_279/quality,Q_100/sharpen,80/format,avif')
}

//原!ai_index_8别名的裁图规则
const cuttingRules3 = (url) => {
  return cuttingRulesBaseFunc(url, '/resize,m_lfit,w_430/quality,Q_100/sharpen,80/format,avif')
}

const cuttingRules4 = (url) => {
  return cuttingRulesBaseFunc(url, '/format,avif/quality,Q_100/sharpen,80')
}

const cuttingRules5 = (url) => {
  return cuttingRulesBaseFunc(url, '/resize,h_350,m_lfit')
}

const cuttingRules6 = (url) => {
  return cuttingRulesBaseFunc(url, '/resize,h_209,m_lfit')
}

export {
  cuttingRules1,
  cuttingRules2,
  cuttingRules3,
  cuttingRules4,
  cuttingRules5,
  cuttingRules6
};
