import sensors from 'sa-sdk-javascript'
import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6.js';
import { useCookies } from 'react-cookie';

	function getCookieByName(name) {
		const cookies = document.cookie.split('; ');
		for (let i = 0; i < cookies.length; i++) {
		const [key, value] = cookies[i].split('=');
		if (key === name) {
			return value;
		}
		}
		return null;
  	}

    //注册公共属性
	sensors.registerPage({
		platform_type: 2,
		product_name: 0,
		site: 20,
		page_type: function() {
			try {
				if(window.location.href.indexOf('coCreateHomePage')>=0){
					if(window.referrerUrl != window.location.href){
						window.referrerType = window.currentType?window.currentType:34;
						window.currentType = 34;
						window.referrerUrl = window.location.href;
					}
					return 34;
				}
				else if(window.location.href.indexOf('inspirationHomePage')>=0){
					if(window.referrerUrl != window.location.href){
						window.referrerType = window.currentType?window.currentType:33;
						window.currentType = 33;
						window.referrerUrl = window.location.href;
					}
					return 33;
				}
				else{
					if(window.referrerUrl != window.location.href){
						window.referrerType = window.currentType?window.currentType:35;
						window.currentType = 35;
						window.referrerUrl = window.location.href;
					}
					return 35;
				}
			} catch (error) {
				return 35;
			}
		},
		access_source_page: function() {
			//获取前向地址
			return window.referrerType;
		},
		is_login: function() {
			return getCookieByName('login_token')?true:false;
		},
		last_login_user_id: function() {
			return getCookieByName('Hm_last_login_user_id') || '';
		},
	}) 

	sensors.init({
		// 埋点数据要上报的地址
		server_url: process.env.REACT_APP_ENV == 'pro'?'https://tpdi.3d66.com/sa?project=production':'https://tpdi.3d66.com/sa?project=default',
		// 单页面配置，默认开启，如果页面有锚点设计，需要删除该配置，否则触发锚点会多触发$pageview事件
		is_track_single_page: true,
		// 是否使用客户端本地时间
		use_client_time: true,
		// 是否要在控制台输出log，方便本地调试，上生产记得关闭或者使用变量控制
		send_type:'beacon',
		max_string_length: 6999,
		show_log: process.env.REACT_APP_ENV == 'pro'?false:true,
		heatmap: {
			// 是否开启点击图，default表示开启，自动采集$webClick事件，设置not_collect表示关闭
			clickmap: 'not_collect',
			// 是否开启触达图，default表示开启，自动采集$WebStay事件，设置not_collect表示关闭
			scroll_notice_map: 'default',
			collect_tags:{
				div : true,
				li: true,
				img: true
			},
			custom_property:function( element_target ){
				//比如您需要给有 data=test 属性的标签的点击事件增加自定义属性 name:'aa' ，则代码如下：
				if(element_target.getAttribute('data-btnName')){
					return {
						btn_name:element_target.getAttribute('data-btnName')
					}
				}
			},
		}
	})

	sensors.use(pageleave,{
		heartbeat_interval_time: 5,
		max_duration: 5 * 24 * 60 * 60,
		isCollectUrl: function(url){
			// url 为要采集页面浏览时长的页面地址。
			return true; // 采集
			// return false; // 不采集
		}
	});

	sensors.quick('autoTrack');

	// 把方法挂载到window上
	window.$sensors = {
		sensors
	}