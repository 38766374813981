import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import "./App.less";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "./routes";
import { ConfigProvider, message } from "antd";
import initListener from "./utils/WebIMListener";
import { Empty } from "antd";
import emptyIcon from "./assets/images/smile_dark.png";
import RtcRoom from "./layout/Channel/SideBar/components/RtcRoom/index"
import CheckBrowser from "./components/CheckBrowser";
import liuliuaiUrl from '@/assets/images/liuliuai.png';
import liuliuaiWhileUrl from '@/assets/images/liuliuaiWhile.png';
import loadingPicUrl from '@/assets/images/loadingPic.gif';
import loadingUrl from '@/assets/images/loading.gif';
import loadingPoint from '@/assets/images/loadingPoint.gif';
import { useSensors } from '@/sensorsdata'
import { LlwAiteach } from '@/utils/sensorsFunc';
const { dispatch, getState } = store;
// import sensors from './utils/sa-sdk-javascript-1.26.11/sensorsdata.es6.min.js'


const DefaultIcon = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${emptyIcon})`,
        height: "100%",
        width: "100%",
        backgroundSize: "cover"
      }}
    ></div>
  );
};
const renderEmptyDom = () => {
  return (
    <Empty
      image={<DefaultIcon />}
      imageStyle={{
        height: 60,
        width: 60
      }}
      description={<span></span>}
    ></Empty>
  );
};
message.config({
  duration: 3,
  maxCount: 2,
});

const isSupport = CSS.supports("selector(:where(div))")

function App() {

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const tipRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    initListener();
    let titleObjArr = [
      {
        title: '设计师的一生是奋斗的一生：是与一切丑陋事物抗争的一生。',
        subTitle: '——马西莫·维涅里（Massimo Vignelli）',
      },
      {
        title: '我并不在意流行趋势，基本上，作为设计师，我们创造流行趋势！',
        subTitle: '——彼得•希瑞尔（Peter Schreyer）',
      },
      {
        title: '设计改变一切！',
        subTitle: '——蒂姆·布朗（Tim Brown ）',
      },
      {
        title: '最美的曲线是销售上升的曲线。',
        subTitle: '——雷蒙德·罗维（Raymond Loeway）',
      },
      {
        title: '作为设计师，我的工作就是透视未来！',
        subTitle: '——马克·纽森（Marc Newson）',
      },
      {
        title: '只有与众不同，才能脱颖而出。',
        subTitle: '——菲利普·斯塔克（Philippe Starck）',
      },
      {
        title: '即使是一根线，也要注入生命。',
        subTitle: '——山本耀司（Yohji Yamamot）',
      },
      {
        title: '在地球上创造一个天堂是设计师的任务。',
        subTitle: '——阿尔瓦·阿尔托（Alvar Aalto）',
      }
    ]
    //生成一个0-7的随机数
    let randomNum = Math.floor(Math.random() * 8);
    setTitle(titleObjArr[randomNum].title);
    setSubTitle(titleObjArr[randomNum].subTitle);
  }, []);

  // useEffect(() => {

  //   if(location.pathname.indexOf('coCreateHomePage')>=0){
  //       window.referrerType = window.currentType?window.currentType:34;
  //       window.currentType = 34;
  //   }
  //   else if(location.pathname.indexOf('inspirationHomePage')>=0){
  //       window.referrerType = window.currentType?window.currentType:33;
  //       window.currentType = 33;
  //   }
  //   else{
  //       window.referrerType = window.currentType?window.currentType:35;
  //       window.currentType = 35;
  //   }
  // }, [location.pathname]);

  const clickGuideBtn = () => {
    dispatch.app.updateShowGuide(true);
    LlwAiteach({
      teach_show: '1',
      if_first: '2',  //1-是；2-否
    })
  };

  const closeTip = (event) => {
    event.stopPropagation();
    event.stopPropagation() // 阻止事件冒泡
    event.preventDefault() // 阻止事件默认行为
    tipRef.current.style.display = 'none';
  };

  return (
    <ConfigProvider renderEmpty={renderEmptyDom} autoInsertSpaceInButton={false}
    theme={{
      token: {
        colorPrimary: '#27AE60',
      },
    }}>
      <div className="App">
        <div className="coverLoadView" id="coverLoadView">
          <div className="coverLoadViewBg"></div>
          <div style={{textAlign:'center', width: '100vw'}}>
            <div className="coverLoadViewTitle">
              <img src={liuliuaiWhileUrl} style={{width: '190px'}}/>
            </div>
            <div className="coverLoadViewSubTitle"><span style={{fontSize: '26px'}}>AI</span><span style={{paddingRight:'18px'}}>赋能</span><span>共创未来</span></div>
            {/* <img src={loadingPicUrl}/>
            <br/>
            <div className="coverLoadViewTitle">{title}</div>
            <div className="coverLoadViewSubTitle">{subTitle}</div> */}
            {/* <img src={loadingUrl} style={{position:'relative',right:'-10px'}}/> */}
          </div>
          <div className="loadingBottomWrap">
            <div className="loadingBarView">
              <div className="bear-wrap"><div class="mss-bear mss-lu4"></div></div>
              <div className="loadingBar"></div>
              <div className="loadingBarEmtry"></div>
              <div className="loadingBarText"><span style={{opacity:0.6}}>loading</span><img style={{position:'relative',bottom:'-5px'}} src={loadingPoint}/></div>
            </div>
          </div>
        </div>
        <div className="aiCotopBar">
          {/* <i className="iconfont icon-liuliuai"></i> */}
          <img src={liuliuaiUrl}/>
          <div id="guide_btn" className="guide-text" onClick={()=>{clickGuideBtn()}}><i className="iconfont icon-Frame2"></i>新手教学</div>
          <div ref={tipRef} style={{display:'none'}} id="guide_tip_1" className="guide-tip">后续可以在这里重复观看教学指导哦~<i onClick={(e)=>closeTip(e)} className="iconfont icon-PlusCircle"></i></div>
        </div>
        <Provider store={store}>
          <Routes />
          <RtcRoom/>
          {!isSupport && <CheckBrowser/>}
        </Provider>
      </div>
    </ConfigProvider>
  );
}

export default App;
