import store from "../store";
const { dispatch, getState } = store;

//灵感图页面点击埋点
export function LlwAiInspirationClick(obj={
  inspiration_action: '',  //事件枚举  1-点击【创作同款】
  search_word: '', //搜索词   模型曝光/点击页面为搜索结果页时，搜索词不能为空
  ll_id: '', //素材ID   素材的llid
  position: 0, //当前坑位  
}) {
  window.$sensors.sensors.track('LlwAiInspirationClick', {
    ...obj,
    ai_source_2: getState().app.ai_source
   });
}

//共创页面点击埋点
export function LlwAiGongchuangClick(obj={
  gongchuang_click: '', //事件枚举   1-点击【创作同款】；2-点击【参与共创】；3-点击【发起共创】；4-点击banner广告
  search_word: '', //搜索词   模型曝光/点击页面为搜索结果页时，搜索词不能为空
  model_id: '', //模型ID   gongchuang_click=1,2时上报模型的ID
  position: -1, //当前坑位   gongchuang_click=1,2时曝光坑位组，以坑位顺序上报，不受页码排序影响
}) {
  window.$sensors.sensors.track('LlwAiGongchuangClick', {
    ...obj,
    ai_source_2: getState().app.ai_source
   });
}

//共创页面素材曝光埋点
export function LlwAiModelShow(obj={
  model_id: '', //模型ID   上报模型的ID
  search_word: '',//搜索词   模型曝光/点击页面为搜索结果页时，搜索词不能为空
  position: [],//当前坑位   曝光坑位组，以坑位顺序上报，不受页码排序影响
}) {
  window.$sensors.sensors.track('LlwAiModelShow', {
    ...obj,
    ai_source_2: getState().app.ai_source
   });
}

//消息操作点击埋点
export function LlwAiMessagereply(obj={
  reply_action: '', //事件枚举  1-点赞；2-表情；3-消息回复；4-查看大图；5-点击模型快速跳转绘画
  room_id: '',  //服务器id  上报当前服务器ID
  room_member_nums: 0,  //当前房间人数
  pindao_id: '',   //频道id
  ai_works_id: '',  //作品id
}) {
  window.$sensors.sensors.track('LlwAiMessagereply', {
    ...obj,
    room_member_nums: getState().app.serverUserNumObj[obj.room_id] || 0,
    ai_source_2: getState().app.ai_source
   });
}

//AI面板展示埋点
export function LlwAiAiShow(obj={
  ai_show:"", //事件枚举 1-绘画面板展示；2-共创面板展示；3-训练-正式版本展示；4-训练-训练版本展示；5-训练-新建训练展示；
  room_id:'', //服务器id  上报当前服务器ID
  room_member_nums:0, //服务器内人数  上报当前服务器内人数
  pindao_id:'', //频道id  上报当前所在频道id
}) {
  window.$sensors.sensors.track('LlwAiAiShow', {
    ...obj,
    room_member_nums: getState().app.serverUserNumObj[obj.room_id] || 0,
    ai_source_2: getState().app.ai_source
   });
}

//AI面板点击事件埋点
export function LlwAiAiClick(obj={
  ai_click: '', //事件枚举  1-点击【设为正式模型】；2-点击【删除模型】；3-点击【修改模型描述】
  room_id: '', //服务器id  上报当前服务器ID
  room_member_nums: 0, //服务器内人数
  channel_id: '', //频道ID 上报当前频道ID
}) {
  window.$sensors.sensors.track('LlwAiAiClick', {
    ...obj,
    room_member_nums: getState().app.serverUserNumObj[obj.room_id] || 0,
    ai_source_2: getState().app.ai_source
   });
}

//图片大图详情按钮点击埋点
export function LlwAiPicturedetail(obj={
  picture_action: '', //事件枚举  图片操作类型  1-点赞；2-表情；3-局部重绘；4-下载；5-查看比对；6-去绘画
  ai_works_id: '', //作品ID   查看的是AI绘图作品时上报作品ID
  model_id: '', //模型ID 查看的是训练图片时上报模型ID
  room_id: '', //服务器ID 上报当前服务器ID
  room_member_nums: 0, //服务器内人数
  pindao_id: '', //频道ID 上报当前频道ID
}) {
  window.$sensors.sensors.track('LlwAiPicturedetail', {
    ...obj,
    room_member_nums: getState().app.serverUserNumObj[obj.room_id] || 0,
    ai_source_2: getState().app.ai_source
   });
}


//溜溜网素材曝光
export function LlwResExposure(obj={
  algorithm_type: 0, //算法类型 0：默认值，1：搜索-火山实验组，2：搜索-自研对照组，3:搜索-自研组，4:搜索-火山B大流量，11：详情页推荐-自研新版，12：详情页推荐-自研旧版，13:图搜-自研组，14:图搜-第三方组，15:详情页推荐-自研对照组，16：详情页推荐-火山实验组，17：详情页推荐-默认分组，21：下了又下-自研新版，22：下了又下-自研旧版，31：h5-类目首页推荐新版，32：h5-类目首页推荐旧版，41：小程序-类目首页推荐新版，42：小程序-类目首页推荐旧版，51：分类列表页-搜索，52：分类列表页-推荐，53：分类列表页--默认分组
  algorithm_version: 0, //算法版本 如:1.0，由算法提供
  ab: 0, //算法分流
  action_id: '', //行为ID
  ll_id_arr: [], //素材ID  传入外部id组
  search_word: '', //搜索词   下载来源为：搜索列表页、普通列表页，访问来源页面搜索列表时，搜索词不能为空
  lsof_arr: [], //火山实时解密SOF组
  list_layout_type: '', //列表页布局类型 0：非普通/搜索列表页产生点击则上报，1：常规，2：横向瀑布流，3：纵向瀑布流
  page: '', //当前页码 
  expo_num: 0, //素材曝光个数  本组上报的素材个数
  res_detail: '', //素材详情
}) {
  window.$sensors.sensors.track('LlwResExposure', {
    ...obj
   });
}


//溜溜网素材点击
export function LlwResClick(obj={
  algorithm_type: 0, //算法类型 0：默认值，1：搜索-火山实验组，2：搜索-自研对照组，3:搜索-自研组，4:搜索-火山B大流量，11：详情页推荐-自研新版，12：详情页推荐-自研旧版，13:图搜-自研组，14:图搜-第三方组，15:详情页推荐-自研对照组，16：详情页推荐-火山实验组，17：详情页推荐-默认分组，21：下了又下-自研新版，22：下了又下-自研旧版，31：h5-类目首页推荐新版，32：h5-类目首页推荐旧版，41：小程序-类目首页推荐新版，42：小程序-类目首页推荐旧版，51：分类列表页-搜索，52：分类列表页-推荐，53：分类列表页--默认分组
  algorithm_version: 0, //算法版本 如:1.0，由算法提供
  ab: 0, //算法分流
  request_id: '', //算法ID
  action_id: '', //行为ID
  source_alg: 0, //推荐算法来源  推荐召回算法的来源，算法类型为推荐自研相关都会传入，其余情况传入0
  llw_source_scene: 0,//溜溜网来源场景  0：默认值，1：搜索列表页，2：详情页推荐，3：下载后推荐，4：普通列表页，5：类目首页，6：主站首页，7：合辑详情页，8：图搜列表页，9：分享，10：seo素材频道，11：收藏，12：新瀑布流首页，13：小程序，14：H5，15：移动端首页综合推荐，16：移动端首页同类推荐，17：移动端收藏综合推荐，18：移动端收藏同类推荐，19：暂无(可使用)， 20：溜溜AI
  pay_type: 0, //素材收费类型  0:免费，1:溜币，2精品，3vip
  ll_id: '', //素材ID  传入外部id组
  search_word: '', //搜索词   下载来源为：搜索列表页、普通列表页，访问来源页面搜索列表时，搜索词不能为空
  position: 0, //当前坑位    曝光坑位组，以坑位顺序上报，不受页码排序影响
  page: 0, //当前页码 
  lsof: '', //火山实时解密SOF 
  list_layout_type: '', //列表页布局类型 0：非普通/搜索列表页产生点击则上报，1：常规，2：横向瀑布流，3：纵向瀑布流
}) {
  window.$sensors.sensors.track('LlwResClick', {
    ...obj
   });
}


//新手指导
export function LlwAiteach(obj={
  teach_show: '',  //教学页展示  1-第一步；2-第二步；3-第三步；4-第四步
  if_first: '', //是否第一次展示新手教学   1-是；2-否
}) {
  window.$sensors.sensors.track('LlwAiteach', {
    ...obj,
    ai_source_2: getState().app.ai_source
   });
}
//主要页面展示
export function LlwAiMainPageShow(obj={
  main_page_show: '', //主要页面展示  1-灵感页展示；2-服务器页面展示；3-共创页展示；
  room_type: '', //服务器类型   main_page_show=2时上报当前所在服务器类型：1-公开服务器；2-私密服务器
  room_id: '', //房间ID main_page_show=2时上报当前服务器ID
  room_member_nums: 0, //服务器内人数
}) {
  window.$sensors.sensors.track('LlwAiMainPageShow', {
    ...obj,
    // room_member_nums: getState().app.serverUserNumObj[obj.room_id] || 0,
    ai_source_2: getState().app.ai_source
   });
}


//描述词统计
export function LlwAiCiku(obj={
  ciku: '', //词库使用上报  1-词库弹窗展示；2-词库弹窗点击选择描述词；3-随机词填入描述词填写框
}) {
  window.$sensors.sensors.track('LlwAiCiku', {
    ...obj,
    ai_source_2: getState().app.ai_source
   });
}

//作品管理页
export function LlwAiWorkManage(obj={
  manage_action: '', //主要行为  1-作品页展示-AI作品；2-作品页展示-重绘；3-点击复用指令绘制；4-点击重绘；5-点击删除；6-点击下载；7-点击图片查看大图；8-确认删除图片（二次确认弹窗）
  ai_works_id: '', //作品ID  查看的是AI绘图作品时上报作品ID（切换图片展示时要上报新的ID）
  imgid: '', //图片ID  上报当前查看图片的ID（切换图片展示时要上报新的ID）
}) {
  window.$sensors.sensors.track('LlwAiWorkManage', {
    ...obj,
    ai_source_2: getState().app.ai_source
   });
}

//溜溜AI-主要点击
export function LlwAiMainPageClick(obj={
  main_page_click: '', //功能栏主要按钮点击  1-开始AI绘画；2-开始训练；3-AI改图
  room_type: '', //服务器类型  1-公开服务器；2-私密服务器
  room_id: '', //房间ID  上报当前服务器ID
  pindao_id: '', //频道ID  上报当前频道ID
  pindao_function_draw: '', //频道是否配置AI绘画功能  1-是；2-否
  pindao_function_train: '', //频道是否配置训练功能 1-是；2-否
  pindao_function_change: '', //频道是否配置改图功能 1-是；2-否
  room_member_nums: 0, //服务器内人数 上报当前服务器内人数
}) {
  window.$sensors.sensors.track('LlwAiMainPageClick', {
    ...obj,
    room_member_nums: getState().app.serverUserNumObj[obj.room_id] || 0,
    ai_source_2: getState().app.ai_source
   });
}