import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import lazy from "./LazyComponent";
import { DRAW_SERVICE_ID } from "@/consts"

const MainLayout = () => import("@/layout/Main");
const ContactsLayout = () => import("@/layout/Contacts");
const ChannelLayout = () => import("@/layout/Channel");
const ServerLayout = () => import("@/layout/Server");
const Login = () => import("@/views/Login/login");
const SharePage = () => import("@/views/SharePage");
const CoCreateHomePage = () => import("@/views/CoCreateHomePage");
const InspirationHomePage = () => import("@/views/InspirationHomePage");
const ChannelContent = () => import("@/views/Channel");
const UserInfoLayout = () => import("@/layout/UserInfo");
const ThreadLayout = () => import("@/views/Thread");
const ContactsOperation = () => import("@/views/ContactsOperation");
const Chat = () => import("@/views/Chat");
const ServerSetting = () => import("@/layout/ServerSetting");
const ChannelSetting = () => import("@/layout/ChannelSetting");

const WorkManagePage = () => import("@/views/WorkManage");

const routes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="main" />} />
      <Route path="login" index element={lazy(Login)} />
      <Route path="sharePage/:code" index element={lazy(SharePage)} />
      <Route path="main" element={lazy(MainLayout)}>
        <Route index element={<Navigate to={`channel/${DRAW_SERVICE_ID[process.env.REACT_APP_ENV]}/0`} replace={true} />} />
        <Route path="contacts/" element={lazy(ContactsLayout)}>
          <Route path="index/" element={lazy(ContactsOperation)}></Route>
          <Route
            path="chat/:userId"
            element={lazy(Chat)}
          ></Route>
        </Route>
        <Route path="user/" element={lazy(UserInfoLayout)}></Route>
        <Route path="channel/" element={lazy(ChannelLayout)}>
          <Route
            path=":serverId/:channelId"
            element={lazy(ChannelContent)}
          ></Route>
          <Route
            path=":serverId/:channelId/:threadId"
            element={lazy(ThreadLayout)}
          ></Route>
        </Route>
        <Route path="server/:serverId/setting" element={lazy(ServerSetting)}></Route>
        <Route path="channel/:serverId/:channelId/setting" element={lazy(ChannelSetting)}></Route>
        <Route path="server/*" element={lazy(ServerLayout)}>
          <Route path="discover" element={<div>Server Page</div>}></Route>
        </Route>
        <Route path="coCreateHomePage" element={lazy(CoCreateHomePage)}></Route>
        <Route path="inspirationHomePage" element={lazy(InspirationHomePage)}></Route>
        <Route path="workManagePage" element={lazy(WorkManagePage)}></Route> 
      </Route>
      {/* TODO: 404? */}
    </Routes>
  );
};

export default routes;
